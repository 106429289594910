import React, { Fragment } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/SEO';

export default function Error() {
  const intl = useIntl();

  return (
    <Fragment>
      <SEO title={intl.formatMessage({ id: 'errorHeading' })} />
      <h1>{intl.formatMessage({ id: 'errorHeading' })}</h1>
    </Fragment>
  );
}
